.card__list-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px 12px;
  margin-top: 20px;
  position: relative;
  min-height: 99px;
  flex-grow: 1;
  padding: 20px;
  border-radius: 15px;
  background: #f2f4f7;

  .card {
    min-width: 100px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.card__list-wrapper-mobile {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 3;

  .card {
    min-width: 225px;
  }
}

.card__create-form {
  min-height: auto;
  display: block;
  width: 720px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  &.flex {
    display: flex;
  }

  @media (max-width: 815px) {
    display: block;
    text-align: center;

    &.flex {
      display: block;
    }

    .options {
      text-align: center;
      margin-top: 20px;
    }
  }
}

@keyframes cardAnimation {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

.card {
  border-radius: 12px;
  z-index: 5;
  overflow: hidden;
  background: #fff;

  &:hover {
    box-shadow: $shadow-small;
    transition: all 350ms;
  }

  &:active {
    animation: cardAnimation;
    animation-duration: 350ms;
    box-shadow: $shadow-small;
    transition: all 350ms;
  }

  .card-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 46px;
    padding: 25px 15px;
    position: relative;
    color: #fff;
    cursor: pointer;

    .icon {
      transition: transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      display: block;
      color: #7c828d;
      width: 16px;
      height: 16px;
      margin-left: 7px;
      position: relative;
      fill: #b9bec7;
    }

    .card-body-content {
      min-width: 0;
      flex-grow: 1;
      position: relative;

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 500;
        color: #292d34;
      }

      .sub-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 4px;
        padding-bottom: 1px;
        font: 400 12px/1 Gotham Pro, Proxima Nova, arial, serif;
        color: #7c828d;
      }
    }
  }
}

.card.active {
  background: rgba($primary, 0.08);

  .card-wrapper {
    .card-body-content,
    .icon {
      color: $primary;

      .title,
      .sub-title {
        color: $primary;
      }
    }
  }
}

.card__wrapper {
  width: 70%;
  height: 190px;
  top: -35px;
  left: -30px;
  position: absolute;
  transform: rotate(-25deg);
  background: $primary;
  border-radius: $border-radius * 2;
  z-index: -1;
}

//Card Pricing
.card__pricing-wrapper {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;

  @media (max-width: 1150px) {
    justify-content: flex-start;
    overflow-x: auto;
  }
}

.card__pricing-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 120px;
  width: 100%;
}

.card__pricing-head {
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  min-height: 85px;
}

.card__pricing-head-title {
  display: flex;
  margin-block-end: 0 !important;
  justify-content: center;
  font-weight: 400 !important;
  color: #4c8af0 !important;

  span {
    font-weight: 600 !important;
    font-size: 60px;
    margin-top: -25px;
  }
}

.grid-template-columns-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.grid-template-columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.grid-template-columns-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.card-template {
  width: 600px;
  max-width: 100%;
  height: 360px;
  border-radius: 12px;
  margin: 10px;
  box-shadow: 0 2px 9px 0 rgba(100, 100, 100, 0.3);

  .card-template-body {
    width: 100%;
    height: 325px;
    padding: 5px;
    transform: scale(1);
    overflow: hidden;
    border-radius: 12px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);
  }

  .card-template-footer {
    padding: 5px 10px;
    overflow: hidden;
  }
}

.card-template:hover {
  border: 1px solid $primary;
  box-shadow: 0 2px 9px 0 rgba(100, 100, 100, 0.5);
}

.card-template.active {
  border: 1px solid $primary;
  box-shadow: 0 2px 9px 0 rgba(100, 100, 100, 0.5);
}

.card-template:hover > .layer {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.card__card {
  width: 260px;
  overflow: hidden;
  border-width: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;

  &.feature {
    margin-top: -20px;
    min-height: calc(100% + 40px);
  }

  &.children {
    min-height: 650px;
    cursor: inherit;
  }

  &.active {
    border: 1px solid #4c8af0;
    box-shadow: 0 0 8px 0 rgba(76, 138, 240, 0.5);
  }

  .ant-switch.ant-switch-checked {
    background: #4c8af0;
  }

  .card__footer {
    a button {
      width: calc(100% - 40px);
      background: #7b68ee;
      border-color: #7b68ee;
    }
  }
}

.card__favorite {
  position: absolute;
  width: 145px;
  top: -34px;
  left: calc(100% - 100px);
  padding: 9px 0 7px;
  color: #fff;
  background: #7b68ee;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  font: 500 10px Axiforma, Arial, serif;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.settings-container {
  width: 100%;
  min-height: 75vh;
  padding: 16px;
  background: #f2f4f7;
}

.settings-content {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
}

.settings-menu {
  width: 250px;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.settings-view {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.current-plan-badge {
  background: #e6f4ea;
  color: #207245;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.divider {
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
}

.switch-container {
  margin: 16px 0;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.check-icon {
  color: #52c41a;
  margin-right: 8px;
}

.action-button-container {
  margin-top: auto;
  padding-top: 16px;
}

.pricing-period {
  font-size: 14px;
  color: #8c8c8c;
}

.plan-head {
  font-size: 10px;
  color: #8c8c8c;
  text-align: left;
  margin: 0;
}

.email-preview-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.email-header {
  background: #2c2c2c;
  padding: 10px;
  display: flex;
  align-items: center;
}

.email-buttons {
  display: flex;
  gap: 5px;
}

.email-buttons span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.red {
  background: #ff5f56;
}
.yellow {
  background: #ffbd2e;
}
.green {
  background: #27c93f;
}

.email-content {
  padding: 15px;
  width: 100%;
}

.email-to,
.email-subject {
  border-bottom: 1px solid #ccc;
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.email-body {
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
}

.email-body-color {
  color: #666;
}

.email-body-preview {
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
}

.preview-content {
  width: 100%;
  overflow-y: auto;
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .email-preview-container {
    max-width: 100%;
    border-radius: 0;
  }
}
